import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At PCMH Restore Health we offer expert care for the health needs of people from all sections of the society. We believe in preventing a health problem than treating one. We strive to build long-term and continuing trust between the patient and the doctor.`}</p>
    <p>{`When a patient walks into our center, we as a team of clinicians meet the patient to understand the problem from the patient’s perspective. We spend a considerable amount of time with our patients trying to analyze his medical, emotional, social and financial problems so as to design care and treatment for every patient as per their need. A standard protocol for every medical or psychological problem is in place for everyone to follow, which is evidence-based. In addition to this, we ensure our team is continually updated on medical advances through research, education and training.`}</p>
    <p>{`We have expertise in managing chronic infections like HIV, Hepatitis B, Hepatitis C, Tuberculosis and other infectious diseases. We not just focus on treatment of an infection but help our patients and their families live life to its fullest. We counsel and treat couples to have healthy babies, without any fear of passing the infection to their child. We teach individuals to manage their illness with not just medication but eating right, exercising and working productively to support their families. We ensure that our patients live healthy and are a pillar of strength not just to their families but the society at large.`}</p>
    <p>{`We have a program specially designed for the youth, where we offer them a non-judgmental, compassionate care for not just matters related to sexual health but psychological issues particular to them. Sexual health is a state of physical, emotional, mental and social well-being in relation to sexuality; it is not merely the absence of disease, dysfunction or infirmity. Sexual health requires a positive and respectful approach to sexuality and sexual relationships, as well as the possibility of having pleasurable and safe sexual experiences, free of coercion, discrimination, and violence. While sexually transmitted diseases (STDs) affect individuals of all ages, STDs take a particularly heavy toll on young people.`}</p>
    <h2>{`HIV Medicine`}</h2>
    <p>{`HIV care is a rapidly evolving field of medicine, with treatment and guidelines being updated regularly. With optimal care and support a person with HIV can lead a normal, fulfilling and active life. Our team at Restore health has over 10 years of experience with helping HIV positive individuals. Our multidisciplinary team takes immense pride in regularly updating themselves with the latest from the medical world. We believe that a holistic approach including nutritional support and family counselling and not just clinical approach to ensure that our patients make the best of their lives.`}</p>
    <h3>{`Exposure to HIV`}</h3>
    <p>{`Exposure to HIV can occur by a number of ways: unprotected sexual encounters, multiple partners, having STDs, infected injections. At Restore Health, we provide counselling on methods of prevention of HIV.  In case of risk of exposure to HIV, pre-exposure prophylaxis (PrEP) reduces risk of transmission of infection. In case of exposure of to HIV, post exposure prophylaxis (PEP) decreases chances of acquiring the infection. In addition to the above, we provide all rounded care with health education, counselling and raising awareness.`}</p>
    <h3>{`Starting with HIV treatment`}</h3>
    <p>{`HIV care is a multi-step process that starts with helping patients understand and accept their HIV positive status and involves multiple other components involving baseline investigations to understand a patient’s current health viral load and genotype is recommended to understand the viral count and used to detect the presence of drug resistant mutations to ensure that treatments can be tailored to the patient’s needs. Starting treatment with HIV is a major life change and a patient will need support on multiple levels which our team is aptly equipped to do so.`}</p>
    <h3>{`Living with HIV`}</h3>
    <p>{`Major objectives of care for a person living with HIV (PLHIV) and taking regular treatment is ensuring that the treatment is effective. Effective ART will produce an undetectable viral load after a period of three months. Regular viral load tests and genotyping will be required to provide optimal treatment. PLHIV are at a risk of becoming immunocompromised and will need a well-trained team to identify side effects and early failure of ART, to initiate change in treatment at the right time and to ensure that the treatment is optimal. Families of positive parents wishing to have children can do so if appropriate precautions and care is ensured.`}</p>
    <h3>{`HIV AIDS Facts`}</h3>
    <ul>
      <li parentName="ul">{`Human Immunodeficiency Virus (HIV) infects cells of immune system`}</li>
      <li parentName="ul">{`AIDS refers to the most advanced stages of HIV`}</li>
      <li parentName="ul">{`HIV is transmitted by sexual route (vaginal/anal/oral sex), transfusion of contaminated blood/tissue, sharing contaminated needles, mother to baby transmission`}</li>
      <li parentName="ul">{`Anti Retroviral Therapy (ART) stops HIV from multiplying in the body`}</li>
    </ul>
    <h2>{`Busting Myths`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`HIV does not spread by saliva, sweat, tears, casual contact, sharing toilets`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`HIV is not transmitted by mosquitoes`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`With Anti Retroviral Treatment, an individual can lead a normal, long life`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Individuals on treatment can have children`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`HIV can spread in both homosexuals and heterosexuals`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Individuals  on HIV treatment should continue to practice safe sex`}</p>
      </li>
    </ul>
    <h2>{`Prevention better than cure`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Use condoms for every sexual encounter`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Adopt less risky sexual behaviors: reducing  number of partners can reduce risk of acquiring HIV infection`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Get yourself tested for  sexually transmitted infections`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Sharing needles/contaminated needles for injecting drugs increases risk`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Regular check ups is important; encourage partner to get tested`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Pre exposure and post exposure prophylaxis (PrEP and PEP)`}</p>
      </li>
    </ul>
    <h3>{`Prevention`}</h3>
    <p>{`Before - PrEP
Sex/Exposure - Condoms
After - Post Exposure Prophylaxis`}</p>
    <p>{`Pre Exposure Prophylaxis (PrEP) is a relatively new HIV prevention strategy that is instituted prior to exposure and establishment of HIV infection among individuals at high risk of acquiring HIV infection. The effectiveness depends on adherence to daily medication. Additionally, HIV PrEP will not prevent other sexually transmitted infections. Therefore it is recommended that those who are on PrEP should preferably also use condoms. Long term prophylaxis is not recommended on account of drug toxicity and development of drug resistance.`}</p>
    <p>{`Post Exposure Prophylaxis (PEP) must be started in emergency situations within 72 hours daily for 28 days, after a recent possible exposure to HIV. The sooner you start PEP, the better. PEP is effective in preventing HIV when administered correctly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      